import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import styles from "./index.module.css"
import MyResume from "../../static/Patrick_O'Hern_Resume.pdf"

export default function Home() {
  return (
    <Layout>
      {/* <Header headerText="Home" /> */}
      <h1 className={styles.header}>
        Hello, my name is Patrick O'Hern, I'm a Full-Stack Software Engineer
        living in Chicago, Illinois.
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10%",
          marginTop: "5%",
        }}
      >
        <img
          src="https://i.imgur.com/BRlU3tph.jpg"
          title="source: imgur.com"
          alt="Headshot"
          className={styles.picture}
        />
        <hr />
        <h2 className={styles.header}>About:</h2>
        <p className={styles.p}>
          From bar manager to full-stack software engineer, I always strive to
          alleviate the problem and come to the solution in a level-headed
          manner, working quickly to solve problems in high pressure situations.
          I strive for autonomy and work life balance. I look forward to using
          my past experience to help solve the problems of today. I'd love to
          connect!
        </p>
        <hr />
        <h2 className={styles.header}>Fun Facts:</h2>
        <ul className={styles.list}>
          <li className={styles.about}>Dog Lover</li>
          <li className={styles.about}>Craft Beer enthusiast</li>
          <li className={styles.about}>Love the outdoors</li>
          <li className={styles.about}>New to Chicago</li>
          <li className={styles.about}>Pizza Lover</li>
        </ul>
        <hr />
        <h2 className={styles.center}>My Resume:</h2>
        <a
          className={styles.index}
          href={MyResume}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://i.pinimg.com/originals/7f/d2/e4/7fd2e46b2da9819e667fb75caf475cf7.png"
            className={styles.resume}
            alt="Resume"
          ></img>
        </a>
        <hr />
        <h2 className={styles.header}>Skills:</h2>
        <div className={styles.index}>
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/react/react.png"
            alt="ReactImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/html/html.png"
            alt="HTMLImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/javascript/javascript.png"
            alt="JSImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/css/css.png"
            alt="CSSImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/bootstrap/bootstrap.png"
            alt="BootstrapImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/nodejs/nodejs.png"
            alt="NodeImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/python/python.png"
            alt="PythonImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/django/django.png"
            alt="DjangoImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/mongodb/mongodb.png"
            alt="MongoDBImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/sql/sql.png"
            alt="SQLImg"
          />
        </div>
        <hr />
        <div className={styles.email}>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/thanks"
            style={{ textDecoration: "left" }}
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <p
              className={styles.emailText}
              style={{
                textDecoration: "underline",
                color: "#003482",
                textAlign: "left",
              }}
            >
              Send Me An Email:
            </p>
            <p className={styles.fields}>
              <label>
                <input
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  style={{ textAlign: "center" }}
                />
              </label>
            </p>
            <p className={styles.fields}>
              <label>
                <input
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  style={{ textAlign: "center" }}
                />
              </label>
            </p>
            <p className={styles.fields}>
              <label>
                <textarea
                  name="message"
                  placeholder="Your Message"
                  style={{ textAlign: "center" }}
                ></textarea>
              </label>
            </p>
            <p className={styles.fields}>
              <button type="submit">Send</button>
            </p>
          </form>
        </div>
        <hr />
        {/* <p className={styles.header} style={{ textAlign: "center" }}>
          Some of my work:
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={styles.card}>
            <p>War</p>
            <img
              className={styles.preview}
              src="https://i.imgur.com/d9UovfWt.png?1"
            ></img>
            <img
              className={styles.preview}
              src="https://i.imgur.com/d9UovfWt.png?1"
            ></img>
          </div>
        </div> */}
        {/* <h3 className={styles.about}>I recently moved to Chicago, after my time in Oxford, MS. I am a dog lover, and have two rescue black lab mixes, that love playing and snuggling. I'd rather be outside hiking, biking, or fishing. Craft beer enthusiast and pizza lover. Thanks for checking out my portfolio, if you have any questions please <Link to='/contact'>reach out</Link> to me!</h3> */}
      </div>
    </Layout>
  )
}
